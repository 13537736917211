.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 23px;
  vertical-align: middle;
}

@keyframes App-logo-spin {
  /* from { transform: rotate(0deg); } */
  /* to { transform: rotate(360deg); } */
}
